var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.fastCarregando)?_c('div',{staticClass:"d-flex justify-content-center text-center"},[_vm._v(" Carregando... ")]):_c('div',[_c('h6',[_vm._v("Gerenciar gateways de pagamento")]),_vm._m(0),(
        _vm.fastGatewaysPagamento.filter((i) => i.id_pessoa_gateway !== null).length
      )?_c('div',_vm._l((_vm.fastGatewaysPagamento.filter(
          (i) => i.id_pessoa_gateway !== null
        )),function(gateway){return _c('div',{key:gateway.id_pagamento_gateway},[_c('div',{staticClass:"d-flex justify-content-between align-itens-center p-2 my-2 border rounded"},[_c('span',{staticClass:"mx-2 gateway-name-size"},[_vm._v(_vm._s(gateway.nome_gateway))]),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(gateway.id_pessoa_gateway))]),_c('span',{on:{"click":function($event){return _vm.exibeModalConfirmaRemoverGateway(
                gateway.nome_gateway,
                gateway.id_pagamento_gateway,
                gateway.id_pessoa_gateway
              )}}},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.up.html",value:({
                title: _vm.criaTooltip('RMV'),
                delay: { show: 0 },
              }),expression:"{\n                title: criaTooltip('RMV'),\n                delay: { show: 0 },\n              }",modifiers:{"hover":true,"up":true,"html":true}}],staticClass:"icon-size",attrs:{"src":require("../../../assets/images/app/secretaria-solicitacoes-matricula-rejeitar.png")}})])])])}),0):_c('div',{staticClass:"d-flex justify-content-center"},[_c('span',{staticClass:"text-danger"},[_vm._v(" Ainda não há gateways vinculados a esta pessoa ")])]),_c('div',{staticClass:"border rounded p-2 my-3"},[_c('span',{staticClass:"my-2"},[_vm._v("Vincular novo gateway de pagamento")]),_c('div',{staticClass:"d-flex justify-content-between align-itens-center my-2"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastGatewayPagamento.id_pagamento_gateway),expression:"fastGatewayPagamento.id_pagamento_gateway"}],staticClass:"form-control w-25",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.fastGatewayPagamento, "id_pagamento_gateway", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Selcione")]),_vm._l((_vm.fastGatewaysPagamento.filter(
              (i) => i.id_pessoa_gateway === null
            )),function(gateway){return _c('option',{key:gateway.id_pagamento_gateway,domProps:{"value":gateway.id_pagamento_gateway}},[_vm._v(" "+_vm._s(gateway.nome_gateway)+" ")])})],2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fastGatewayPagamento.id_pessoa_gateway),expression:"fastGatewayPagamento.id_pessoa_gateway"},{name:"b-tooltip",rawName:"v-b-tooltip.hover.up.html",value:({
            title: _vm.criaTooltip('ID'),
            delay: { show: 0 },
          }),expression:"{\n            title: criaTooltip('ID'),\n            delay: { show: 0 },\n          }",modifiers:{"hover":true,"up":true,"html":true}}],staticClass:"form-control w-50",attrs:{"type":"text","placeholder":"ID do gateway"},domProps:{"value":(_vm.fastGatewayPagamento.id_pessoa_gateway)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fastGatewayPagamento, "id_pessoa_gateway", $event.target.value)}}}),_c('span',{on:{"click":function($event){return _vm.postAdicionaGatewayPagamento()}}},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.up.html",value:({
                title: _vm.criaTooltip('ADD'),
                delay: { show: 0 },
              }),expression:"{\n                title: criaTooltip('ADD'),\n                delay: { show: 0 },\n              }",modifiers:{"hover":true,"up":true,"html":true}}],staticClass:"icon-size-add",attrs:{"src":require("../../../assets/images/app/secretaria-solicitacoes-matricula-aprovar.png")}})])])])]),_c('modal',{attrs:{"name":"modalConfirmaRemoverGateway","shift-y":0.1,"height":"auto","scrollable":true,"adaptative":true}},[_c('div',{staticClass:"d-flex justify-content-between p-3"},[_c('h5',[_vm._v("Remover gateway de pagamento?")]),_c('a',{staticClass:"btn btn-secondary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.hideModal('modalConfirmaRemoverGateway')}}},[_c('b-icon-arrow-return-left')],1)]),_c('div',{staticClass:"p-3 w-100 d-flex flex-column justify-content-center text-center"},[_c('span',{staticClass:"mb-3"},[_vm._v("Gateway: "+_vm._s(_vm.fastRemoveGatewayPagamento.nome_gateway))]),_c('a',{staticClass:"btn btn-danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.postRemoveGatewayPagamento()}}},[_vm._v(" Remover ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-secondary text-white d-flex justify-content-between align-itens-center p-2 my-2 border rounded"},[_c('span',{staticClass:"mx-2 gateway-name-size"},[_vm._v("Nome do gateway")]),_c('span',{staticClass:"mx-2"},[_vm._v("ID do gateway")]),_c('span',[_vm._v("Ações")])])
}]

export { render, staticRenderFns }