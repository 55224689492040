<template>
  <div>
    <div v-if="fastCarregando" class="d-flex justify-content-center text-center">
      Carregando...
    </div>
    <div v-else>
      <h6>Gerenciar gateways de pagamento</h6>
      <div
        class="bg-secondary text-white d-flex justify-content-between align-itens-center p-2 my-2 border rounded"
      >
        <span class="mx-2 gateway-name-size">Nome do gateway</span>
        <span class="mx-2">ID do gateway</span>
        <span>Ações</span>
      </div>
      <div
        v-if="
          fastGatewaysPagamento.filter((i) => i.id_pessoa_gateway !== null).length
        "
      >
        <div
          v-for="gateway in fastGatewaysPagamento.filter(
            (i) => i.id_pessoa_gateway !== null
          )"
          :key="gateway.id_pagamento_gateway"
        >
          <div
            class="d-flex justify-content-between align-itens-center p-2 my-2 border rounded"
          >
            <span class="mx-2 gateway-name-size">{{ gateway.nome_gateway }}</span>
            <span class="mx-2">{{ gateway.id_pessoa_gateway }}</span>
            <span
              @click="
                exibeModalConfirmaRemoverGateway(
                  gateway.nome_gateway,
                  gateway.id_pagamento_gateway,
                  gateway.id_pessoa_gateway
                )
              "
              ><img
                src="../../../assets/images/app/secretaria-solicitacoes-matricula-rejeitar.png"
                class="icon-size"
                v-b-tooltip.hover.up.html="{
                  title: criaTooltip('RMV'),
                  delay: { show: 0 },
                }"
            /></span>
          </div>
        </div>
      </div>
      <div v-else class="d-flex justify-content-center">
        <span class="text-danger">
          Ainda não há gateways vinculados a esta pessoa
        </span>
      </div>
      <div class="border rounded p-2 my-3">
        <span class="my-2">Vincular novo gateway de pagamento</span>
        <div class="d-flex justify-content-between align-itens-center my-2">
          <select
            class="form-control w-25"
            v-model="fastGatewayPagamento.id_pagamento_gateway"
          >
            <option value="">Selcione</option>
            <option
              v-for="gateway in fastGatewaysPagamento.filter(
                (i) => i.id_pessoa_gateway === null
              )"
              :value="gateway.id_pagamento_gateway"
              :key="gateway.id_pagamento_gateway"
            >
              {{ gateway.nome_gateway }}
            </option>
          </select>
          <input
            type="text"
            class="form-control w-50"
            placeholder="ID do gateway"
            v-model="fastGatewayPagamento.id_pessoa_gateway"
            v-b-tooltip.hover.up.html="{
              title: criaTooltip('ID'),
              delay: { show: 0 },
            }"
          />
          <span @click="postAdicionaGatewayPagamento()"
            ><img
              src="../../../assets/images/app/secretaria-solicitacoes-matricula-aprovar.png"
              class="icon-size-add"
              v-b-tooltip.hover.up.html="{
                  title: criaTooltip('ADD'),
                  delay: { show: 0 },
                }"
          /></span>
        </div>
      </div>
    </div>
    <modal
      name="modalConfirmaRemoverGateway"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="d-flex justify-content-between p-3">
        <h5>Remover gateway de pagamento?</h5>
        <a
          class="btn btn-secondary"
          href="#"
          @click.prevent="hideModal('modalConfirmaRemoverGateway')"
        >
          <b-icon-arrow-return-left />
        </a>
      </div>
      <div
        class="p-3 w-100 d-flex flex-column justify-content-center text-center"
      >
        <span class="mb-3"
          >Gateway: {{ fastRemoveGatewayPagamento.nome_gateway }}</span
        >
        <a
          class="btn btn-danger"
          href="#"
          @click.prevent="postRemoveGatewayPagamento()"
        >
          Remover
        </a>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "FastPessoaGatewaysPagamento",
  props: {
    id_pessoa: {
      type: Number,
      required: true,
    },
    id_plataforma: {
      type: Number,
      required: true,
    },
  },
  mixins: [methods],
  data: function () {
    return {
      fastCarregando: true,
      fastGatewaysPagamento: [],
      fastGatewayPagamento: {
        id_pagamento_gateway: "",
        id_pessoa_gateway: "",
      },
      fastRemoveGatewayPagamento: {
        nome_gateway: "",
        id_pagamento_gateway: 0,
        id_pessoa_gateway: "",
      },
    };
  },
  mounted: function () {
    this.getPessoaGatewaysPagamento();
  },
  methods: {
    async getPessoaGatewaysPagamento() {
      this.fastCarregando = true;
      this.promiseGetFastApi(
        "api/fast_pagamento_gateway_plataforma_pessoa/lista_pessoa",
        `id_plataforma=${this.id_plataforma}&id_pessoa=${this.id_pessoa}`
      )
        .then((res) => {
          if (res) {
            console.log(res);
            this.fastGatewaysPagamento = res;
          }
          this.fastCarregando = false;
        })
        .catch((e) => {
          console.log(e);
          this.fastCarregando = false;
        });
    },
    async postAdicionaGatewayPagamento() {
      let obj = {
        id_pagamento_gateway: this.fastGatewayPagamento.id_pagamento_gateway,
        id_plataforma: this.id_plataforma,
        id_pessoa: this.id_pessoa,
        id_pessoa_gateway: this.fastGatewayPagamento.id_pessoa_gateway,
      };
      // console.log(obj);
      if (
        !this.fastGatewayPagamento.id_pagamento_gateway ||
        !this.fastGatewayPagamento.id_pessoa_gateway
      ) {
        this.exibeToasty(
          "Por favor, escolha o gateway e insira o respectivo ID",
          "error"
        );
      } else {
        this.fastCarregando = true;
        this.promisePostFastApi(
          obj,
          "api/fast_pagamento_gateway_plataforma_pessoa/atualiza"
        )
          .then((res) => {
            if (res.success) {
              this.exibeToasty("Gateway atualizado", "success");
              this.getPessoaGatewaysPagamento();
              this.fastGatewayPagamento.id_pagamento_gateway = "";
              this.fastGatewayPagamento.id_pessoa_gateway = "";
            } else {
              console.log(res);
              this.exibeToasty("Ocorreu um erro", "error");
            }
          })
          .catch((e) => {
            console.log(e);
            this.exibeToasty(e, "error");
            this.fastCarregando = false;
          });
      }
    },
    async postRemoveGatewayPagamento() {
      this.fastCarregando = true;
      let obj = {
        id_pagamento_gateway:
          this.fastRemoveGatewayPagamento.id_pagamento_gateway,
        id_plataforma: this.id_plataforma,
        id_pessoa: this.id_pessoa,
        id_pessoa_gateway: this.fastRemoveGatewayPagamento.id_pessoa_gateway,
      };
      this.promisePostFastApi(
        obj,
        "api/fast_pagamento_gateway_plataforma_pessoa/exclui"
      )
        .then((res) => {
          if (res.success) {
            this.exibeToasty("Gateway atualizado", "success");
            this.hideModal("modalConfirmaRemoverGateway");
            this.getPessoaGatewaysPagamento();
            this.fastRemoveGatewayPagamento.id_pagamento_gateway = "";
            this.fastRemoveGatewayPagamento.id_pessoa_gateway = "";
          } else {
            console.log(res);
            this.exibeToasty("Ocorreu um erro", "error");
          }
        })
        .catch((e) => {
          console.log(e);
          this.exibeToasty(e, "error");
          this.fastCarregando = false;
        });
    },
    exibeModalConfirmaRemoverGateway(
      nome_gateway,
      id_pagamento_gateway,
      id_pessoa_gateway
    ) {
      this.fastRemoveGatewayPagamento.nome_gateway = nome_gateway;
      this.fastRemoveGatewayPagamento.id_pagamento_gateway =
        id_pagamento_gateway;
      this.fastRemoveGatewayPagamento.id_pessoa_gateway = id_pessoa_gateway;
      this.showModal("modalConfirmaRemoverGateway");
    },
    criaTooltip(e) {
      switch (e) {
        case 'ADD':
          return `<div class="text-left">
            Adicionar gateway 
          </div>`;
        case 'RMV':
          return `<div class="text-left">
            Remover gateway 
          </div>`;
        case 'ID':
          return `<div class="text-left">
            Este identificador pode ser obtido mediante a consulta da documentação oficial do respectivo gateway 
          </div>`;
      }
    },
  },
};
</script>

<style>
.gateway-name-size {
  width: 10rem;
}
.icon-size,
.icon-size-add {
  cursor: pointer;
}
.icon-size:hover,
.icon-size-add:hover {
  opacity: 0.5;
}
.icon-size {
  height: 20px;
  width: 20px;
}
.icon-size-add {
  height: 25px;
  width: 25px;
  margin-top: 5px;
}
.form-control-gateway {
  width: 30%;
}
</style>
