<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
              >Modo tesouraria</a>              
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Pessoas</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Pessoas da
                  <span>plataforma</span>
                </h1>
                <img
                  :src="require('@/assets/images/form-effect-01.png')"
                  style="max-height: 35px"
                >
              </section>
              <!-- /fd-app-welcome -->
            </div>
          </div>
        </div>
        <div class="container">
          <!-- Conteúdo principal -->
          <div class="row mt-4">
            <transition name="slide-fade">
              <div
                v-if="fastNavegacao.exibirPessoas"
                class="col-12"
              >                  
                <div class="card shadow mb-4">
                  <div class="card-header py-3">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-6">
                        <h6 class="m-0 font-weight-bold text-primary">
                          Lista de pessoas da plataforma ({{ fastPessoasPlataforma.total.length }})
                        </h6>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                        <button
                          class="btn btn-sm btn-primary"
                          @click="exibeModalCadastrarPessoa()"
                        >
                          <small>+ Cadastrar pessoa</small>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          placeholder="Filtrar por nome ou email"
                          v-model="fastPessoasPlataforma.filtroParametros.nome_email.valor"
                          @keyup="filtraPessoas()"
                        >
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                        <select class="form-control form-control-sm" v-model="fastPessoasPlataforma.filtroParametros.perfil.valor" @change="filtraPessoas">
                          <option value="">-- Selecione um perfil --</option>
                          <option value="administrador">Administrador</option>
                          <option value="aluno">Aluno</option>
                          <option value="professor">Professor</option>
                          <option value="secretaria">Secretaria</option>
                          <option value="tesouraria">Tesouraria</option>
                          <option value="responsavel">Responsavel</option>
                        </select>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4 text-right d-flex justify-content-end align-items-center">
                        <a
                          class="text-success fast-cursor-refresh"
                          @click.prevent="getUsuariosPlataforma($route.params.id_plataforma)"
                        >
                          <b-icon-arrow-counterclockwise font-scale="2" />
                        </a>
                      </div>
                      <div class="col-12 table-responsive mt-2">
                        <table class="table table-sm">
                          <thead class="thead-dark">
                            <tr>
                              <th><small class="font-weight-bold">Nome</small></th>
                              <th><small class="font-weight-bold">Email</small></th>
                              <th class="text-center">
                                <small class="font-weight-bold">Perfil</small>
                              </th>
                              <th class="text-center">
                                <small class="font-weight-bold">Ativo</small>
                              </th>
                              <th class="text-center">
                                <small class="font-weight-bold">Data de cadastro</small>
                              </th>
                              <th class="text-center">
                                <small class="font-weight-bold">Ações</small>
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="fastPessoasPlataforma.loading">
                            <tr>
                              <td
                                colspan="6"
                                class="text-center"
                              >
                              <b-icon icon="gear-fill" animation="spin"/> Carregando pessoas 
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else-if="fastPessoasPlataforma.paginacao.length">
                            <tr
                              v-for="(e, index) in fastPessoasPlataforma.paginacao"
                              :key="index"
                            >
                              <td class="align-middle">
                                <small>{{ e.nome_razao + " " + e.sobrenome_fantasia }}</small>                                  
                              </td>
                              <td class="align-middle">
                                <small>{{ e.email }}</small>                                  
                              </td>
                              <td class="align-middle text-left">
                                <div
                                  v-if="e.administrador == 'S'"
                                  class="text-success"
                                >
                                  <small><b-icon-check-circle-fill /> Administrador</small>                                      
                                </div>
                                <div
                                  v-if="e.professor == 'S'"
                                  class="text-success"
                                >
                                  <small><b-icon-check-circle-fill /> Professor</small>                                      
                                </div>
                                <div
                                  v-if="e.aluno == 'S'"
                                  class="text-success"
                                >
                                  <small><b-icon-check-circle-fill /> Aluno</small>                                      
                                </div>
                                <div
                                  v-if="e.tesouraria == 'S'"
                                  class="text-success"
                                >
                                  <small><b-icon-check-circle-fill /> Tesouraria</small>                                      
                                </div>
                                <div
                                  v-if="e.secretario == 'S'"
                                  class="text-success"
                                >
                                  <small><b-icon-check-circle-fill /> Secretario</small>                                      
                                </div>
                                <div
                                  v-if="e.responsavel == 'S'"
                                  class="text-success"
                                >
                                  <small><b-icon-check-circle-fill /> Responsavel</small>                                      
                                </div>      
                                <div
                                  v-if="e.administrador != 'S' && e.aluno != 'S' && e.professor != 'S' && e.secretaria != 'S' && e.secretario != 'S' && e.responsavel != 'S'"
                                  class="text-warning"
                                >
                                  <small>Sem perfil</small>                                      
                                </div>                         
                              </td>
                              <td class="align-middle text-center">
                                <small
                                  v-if="e.ativo == 'S'"
                                  class="badge badge-success mr-2"
                                >sim</small>
                                <small
                                  v-else
                                  class="badge badge-danger mr-2"
                                >Não</small>                               
                              </td>
                              <td class="align-middle text-center">
                                <small v-if="e.data_cadastro">{{ new Date(e.data_cadastro).toLocaleDateString() }}</small>  
                                <small v-else>Sem registro</small>                              
                              </td>                           
                              <td class="text-center align-middle">       
                                <button
                                  class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                  @click="exibeModalEditarUsuario(e)"
                                >
                                  <small>Detalhes</small>
                                </button>  
                                
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td
                                colspan="6"
                                class="text-center"
                              >
                                Nenhuma pessoa encontrada
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-12 text-center mt-4">
                        <Pagination
                          :page-size="20"
                          :items="fastPessoasPlataforma.filtro"
                          @changePage="fastPessoasPlataforma.paginacao = $event"
                        />
                      </div>
                    </div>
                  </div>
                </div>                  
              </div>
            </transition>
            <transition name="slide-fade">
              <div
                v-if="fastNavegacao.cadastrarPessoa"
                class="col-12"
              >                  
                <div class="card shadow mb-4">
                  <div class="card-header py-3">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <h6 class="m-0 font-weight-bold text-primary">
                          Cadastrar nova pessoa
                        </h6>
                      </div>                        
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="col-12 mt-2">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                          <h6>Informações pessoais</h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Primeiro nome <span class="text-danger">*</span></label>
                          <div class="form-group">
                            <input
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa.nome_razao ? 'form-control' : 'form-control text-danger border border-danger'"
                              v-model="modelCadastroUsuario.fast_pessoa.nome_razao"                              
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Sobrenome <span class="text-danger">*</span></label>
                          <div class="form-group">
                            <input
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa.sobrenome_fantasia ? 'form-control' : 'form-control text-danger border border-danger'"
                              v-model="modelCadastroUsuario.fast_pessoa.sobrenome_fantasia"                              
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Email <span class="text-danger">*</span></label>
                          <div class="form-group">
                            <input
                              v-model="modelCadastroUsuario.fast_usuario.email"
                              :class="modelCadastroUsuario.fast_usuario.email ? 'form-control' : 'form-control text-danger border border-danger'"
                              type="text"                              
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Data de nascimento / Data de abertura <span class="text-danger">*</span></label>
                          <div class="form-group">
                            <input
                              v-model="
                              modelCadastroUsuario.fast_pessoa.datanascimento_abertura
                              "
                              :class="modelCadastroUsuario.fast_pessoa.datanascimento_abertura ? 'form-control' : 'form-control text-danger border border-danger'"
                              type="date"
                              autocomplete="no"
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>CPF/CNPJ <span class="text-danger">*</span></label>
                          <div class="form-group">
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa.cpf_cnpj"
                              v-mask="[
                                '###.###.###-##',
                                '##.###.###/####-##',
                              ]"
                              :class="modelCadastroUsuario.fast_pessoa.cpf_cnpj ? 'form-control' : 'form-control text-danger border border-danger'"
                              type="text"
                              autocomplete="no"
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Sexo</label>
                          <div class="form-group">
                            <select
                              v-model="modelCadastroUsuario.fast_pessoa.sexo"
                              class="form-control"                            
                            >
                              <option value="">
                                Indefinido
                              </option>
                              <option value="M">
                                Masculino
                              </option>
                              <option value="F">
                                Feminino
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Telefone <span class="text-danger">*</span></label>
                          <div class="form-group">
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_telefone.telefone"
                              v-mask="[
                                '(##) ####-####',
                                '(##) #####-####',
                              ]"
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa_telefone.telefone ? 'form-control' : 'form-control text-danger border border-danger'"
                              autocomplete="no"
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Tipo de pessoa</label>
                          <div class="form-group">
                            <input
                              v-if="modelCadastroUsuario.fast_pessoa.cpf_cnpj.length == 14"
                              type="text"
                              class="form-control"
                              value="Física"
                              readonly
                            >
                            <input
                              v-else-if="modelCadastroUsuario.fast_pessoa.cpf_cnpj.length == 18"
                              type="text"
                              class="form-control"
                              value="Jurídica"
                              readonly
                            >
                            <input
                              v-else
                              type="text"
                              class="form-control"
                              value="Não identificado"
                              readonly
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Identificador gerencianet</label>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              v-model="modelCadastroUsuario.fast_pessoa.payee_code_gerencianet"                              
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                          <h6>Endereço</h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>CEP <span class="text-danger">*</span></label>
                          <div class="form-group">
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_endereco.cep"
                              v-mask="'#####-###'"
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa_endereco.cep ? 'form-control' : 'form-control text-danger border border-danger'"
                              autocomplete="no"
                              @keyup="buscaCEPAluno();"
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Endereço <span class="text-danger">*</span></label>
                          <div class="form-group">
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_endereco.logradouro"
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa_endereco.logradouro ? 'form-control' : 'form-control text-danger border border-danger'"
                              maxlength="100"
                              autocomplete="no"
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Número <span class="text-danger">*</span></label>
                          <div class="form-group">                            
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_endereco.numero"
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa_endereco.numero ? 'form-control' : 'form-control text-danger border border-danger'"
                              maxlength="10"
                              autocomplete="no"
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Complemento</label>
                          <div class="form-group">                           
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_endereco.complemento"
                              type="text"
                              class="form-control"
                              autocomplete="no"
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Bairro <span class="text-danger">*</span></label>
                          <div class="form-group">                           
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_endereco.bairro"
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa_endereco.bairro ? 'form-control' : 'form-control text-danger border border-danger'"
                              autocomplete="no"
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Cidade <span class="text-danger">*</span></label>
                          <div class="form-group">                            
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_endereco.cidade"
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa_endereco.cidade ? 'form-control' : 'form-control text-danger border border-danger'"
                              autocomplete="no"                              
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Estado <span class="text-danger">*</span></label>
                          <div class="form-group">                           
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_endereco.uf"
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa_endereco.uf ? 'form-control' : 'form-control text-danger border border-danger'"
                              maxlength="2"
                              autocomplete="no"
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                          <h6>Funções</h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3" v-for="e in fastFuncaoPessoa.total" :key="e.id_funcao_pessoa">
                          <div class="fd-app-curso-aulas mt-0">
                            <div class="fd-app-curso-aulas-list mt-0">
                              <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                  <div class="col-12 text-center mb-1">
                                    <span>{{e.nome_funcao_pessoa}}</span>
                                  </div>
                                  <div class="col-12 text-center">
                                    <label class="switch">
                                      <input                                                                             
                                        type="checkbox"   
                                        :value="e.id_funcao_pessoa"
                                        @change="alteraFuncaoNovaPessoa"                                        
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 d-none">
                          <p :class="modelCadastroUsuario.classe">{{modelCadastroUsuario.status}}</p>
                        </div>
                      </div>
                      <div class="row">                                        
                        <div class="col-12 mt-2 mb-2">
                          <div class="form-group mb-0">
                            <button
                              v-if="fastEmailCadastro.btnCadastrar"
                              href="#"
                              type="button"
                              class="btn btn-primary mr-1"
                              @click="validaCadastrarPessoaTesouraria()"
                            >
                              <small>Cadastrar pessoa</small>                                
                            </button>
                            <button
                              href="#"
                              type="button"
                              class="btn btn-secondary mr-1"
                              @click.prevent="cancelaConviteUsuarios()"
                            >
                              <small>Cancelar</small>                                
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="slide-fade">
              <div
                v-if="fastNavegacao.exibirDetalhes"
                class="col-12"
              >
                <div class="card shadow mb-4">
                  <div class="card-header py-3">
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <h6 class="m-0 font-weight-bold text-primary">
                          Detalhes da pessoa
                        </h6>
                      </div>                        
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="col-12 mt-2">
                      <fast-cadastro-pessoa 
                      :readonly="false" 
                      :id_pessoa="parseInt(modelCadastroUsuario.fast_pessoa.id_pessoa)" 
                      :id_plataforma="parseInt($route.params.id_plataforma)" />
                      <!-- <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                          <h6>Informações pessoais</h6>
                        </div>
                        <fast-cadastro-pessoa :readonly="false" :perfil="'aluno'" :id_pessoa="parseInt(fastUsuarioEditar.id_pessoa)" :id_plataforma="parseInt($route.params.id_plataforma)" modo_financeiro />
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Tipo de pessoa</label>
                          <div class="form-group">
                            <input
                              v-if="modelCadastroUsuario.fast_pessoa.cpf_cnpj.length == 14"
                              type="text"
                              class="form-control"
                              value="Física"
                              readonly
                            >
                            <input
                              v-else-if="modelCadastroUsuario.fast_pessoa.cpf_cnpj.length == 18"
                              type="text"
                              class="form-control"
                              value="Jurídica"
                              readonly
                            >
                            <input
                              v-else
                              type="text"
                              class="form-control"
                              value="Não identificado"
                              readonly
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Identificador gerencianet</label>
                          <div class="form-group">
                            <input
                              type="text"
                              class="form-control"
                              v-model="modelCadastroUsuario.fast_pessoa.payee_code_gerencianet"                                                            
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                          <h6>Endereço</h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>CEP <span class="text-danger">*</span></label>
                          <div class="form-group">
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_endereco.cep"
                              v-mask="'#####-###'"
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa_endereco.cep ? 'form-control' : 'form-control text-danger border border-danger'"
                              autocomplete="no"
                              @keyup="buscaCEPAluno();"
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Endereço <span class="text-danger">*</span></label>
                          <div class="form-group">
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_endereco.logradouro"
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa_endereco.logradouro ? 'form-control' : 'form-control text-danger border border-danger'"
                              maxlength="100"
                              autocomplete="no"
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Número <span class="text-danger">*</span></label>
                          <div class="form-group">                            
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_endereco.numero"
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa_endereco.numero ? 'form-control' : 'form-control text-danger border border-danger'"
                              maxlength="10"
                              autocomplete="no"
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Complemento</label>
                          <div class="form-group">                           
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_endereco.complemento"
                              type="text"
                              class="form-control"
                              autocomplete="no"
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Bairro <span class="text-danger">*</span></label>
                          <div class="form-group">                           
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_endereco.bairro"
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa_endereco.bairro ? 'form-control' : 'form-control text-danger border border-danger'"
                              autocomplete="no"
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Cidade <span class="text-danger">*</span></label>
                          <div class="form-group">                            
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_endereco.cidade"
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa_endereco.cidade ? 'form-control' : 'form-control text-danger border border-danger'"
                              autocomplete="no"                              
                            >
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <label>Estado <span class="text-danger">*</span></label>
                          <div class="form-group">                           
                            <input
                              v-model="modelCadastroUsuario.fast_pessoa_endereco.uf"
                              type="text"
                              :class="modelCadastroUsuario.fast_pessoa_endereco.uf ? 'form-control' : 'form-control text-danger border border-danger'"
                              maxlength="2"
                              autocomplete="no"
                            >
                          </div>
                        </div>
                      </div> -->
                      <div class="d-flex flex-column">
                        <div class="col-sm-12 col-md-12 col-lg-12 m-2">
                          <h4>Dados da plataforma</h4>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-12">
                          <label>Tipo de pessoa</label>
                          <div class="form-group">
                            <input
                              v-if="modelCadastroUsuario.fast_pessoa.cpf_cnpj.length == 14"
                              type="text"
                              class="form-control"
                              value="Física"
                              readonly
                            >
                            <input
                              v-else-if="modelCadastroUsuario.fast_pessoa.cpf_cnpj.length == 18"
                              type="text"
                              class="form-control"
                              value="Jurídica"
                              readonly
                            >
                            <input
                              v-else
                              type="text"
                              class="form-control"
                              value="Não identificado"
                              readonly
                            >
                          </div>
                          <fast-pessoa-gateways-pagamento 
                          :id_pessoa="parseInt(modelCadastroUsuario.fast_pessoa.id_pessoa)" 
                          :id_plataforma="parseInt($route.params.id_plataforma)" />
                        </div>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                          <h6>Funções</h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3" v-for="e in fastFuncaoPessoa.total" :key="e.id_funcao_pessoa">
                          <div class="fd-app-curso-aulas mt-0">
                            <div class="fd-app-curso-aulas-list mt-0">
                              <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                  <div class="col-12 text-center mb-1">
                                    <span>{{e.nome_funcao_pessoa}}</span>
                                  </div>
                                  <div class="col-12 text-center">
                                    <label class="switch">
                                      <input  
                                        v-if="e.ativa" 
                                        checked="checked"                                                                     
                                        type="checkbox"   
                                        :value="e.id_funcao_pessoa"
                                        @change="alteraFuncaoEditarPessoa"                                        
                                      >
                                      <input  
                                        v-else                                                                    
                                        type="checkbox"   
                                        :value="e.id_funcao_pessoa"
                                        @change="alteraFuncaoEditarPessoa"                                        
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 d-none">
                          <p :class="modelCadastroUsuario.classe">{{modelCadastroUsuario.status}}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                          <h6>Status na plataforma</h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="card border-left-primary h-100">                    
                            <div class="card-body">
                              <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                  <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">                                              
                                    <span v-if="fastUsuarioEditar.nome_razao">{{ fastUsuarioEditar.nome_razao + " " + fastUsuarioEditar.sobrenome_fantasia }}</span>     
                                    <span v-else>Nome não registrado</span>  
                                  </div>
                                  <div class="h5 mb-0 font-weight-bold text-success">
                                    <span>{{ fastUsuarioEditar.email }}</span>  
                                  </div>
                                  <div class="text-xs font-weight-bold text-secondary text-uppercase mt-2">   
                                    <div
                                      v-if="fastUsuarioEditar.administrador == 'S'"
                                      class="text-success"
                                    >
                                      <b-icon-check-circle-fill /> Administrador
                                    </div>
                                    <div
                                      v-if="fastUsuarioEditar.professor == 'S'"
                                      class="text-success"
                                    >
                                      <b-icon-check-circle-fill /> Professor
                                    </div>
                                    <div
                                      v-if="fastUsuarioEditar.aluno == 'S'"
                                      class="text-success"
                                    >
                                      <b-icon-check-circle-fill /> Aluno
                                    </div>
                                    <div
                                      v-if="fastUsuarioEditar.tesouraria == 'S'"
                                      class="text-success"
                                    >
                                      <b-icon-check-circle-fill /> Tesouraria
                                    </div>
                                    <div
                                      v-if="fastUsuarioEditar.secretario == 'S'"
                                      class="text-success"
                                    >
                                      <b-icon-check-circle-fill /> Secretario
                                    </div>
                                    <div
                                      v-if="fastUsuarioEditar.responsavel == 'S'"
                                      class="text-success"
                                    >
                                      <b-icon-check-circle-fill /> Responsavel
                                    </div>                                                                                             
                                  </div>
                                </div>
                                <div class="col-auto">
                                  <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(fastUsuarioEditar.image) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div class="card border-left-primary h-100">                    
                            <div class="card-body">
                              <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                  <div
                                    v-if="fastUsuarioEditar.data_convite_envio"
                                    class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                  >                                              
                                    Adicionado em <span>{{ new Date(fastUsuarioEditar.data_convite_envio).toLocaleDateString() }}</span>  
                                  </div>
                                  <div
                                    v-if="fastUsuarioEditar.data_convite_reenvio"
                                    class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                  >                                              
                                    Reenviado em <span>{{ new Date(fastUsuarioEditar.data_convite_reenvio).toLocaleDateString() }}</span>  
                                  </div>
                                  <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">                                              
                                    Aceito: <span
                                      v-if="fastUsuarioEditar.aceito == 'S'"
                                      class="text-success"
                                    >Sim</span>  
                                    <span
                                      v-else
                                      class="text-danger"
                                    >Não</span>                                                
                                  </div>
                                  <div
                                    v-if="fastUsuarioEditar.data_aceite && fastUsuarioEditar.aceito == 'S'"
                                    class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                  >                                              
                                    Data do aceite: <span>{{ new Date(fastUsuarioEditar.data_aceite).toLocaleDateString() }}</span>                                                 
                                  </div>
                                  <div
                                    v-if="fastUsuarioEditar.token"
                                    class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                  >                                              
                                    <p v-if="fastUsuarioEditar.token == 'desativado'">
                                      <span class="text-danger">Desativado manualmente</span>
                                    </p> 
                                    <p v-else-if="fastUsuarioEditar.token == 'ativado'">
                                      <span class="text-success">Ativado manualmente</span>
                                    </p> 
                                    <div v-else>
                                      <p>Convite pendente: <span class="text-warning"> Sim </span></p>
                                      <!-- Emails em hardcode -->
                                      <p v-if="$store.state.fastUsuario.Email == 'gfbalestrin@hotmail.com' || $store.state.fastUsuario.Email == 'leila@concurseiro.com' || $store.state.fastUsuario.Email == 'austran@fastead.com' || $store.state.fastUsuario.Email == 'gabi@fastead.com'">
                                        Link do convite: 
                                        <span v-if="fastLinkConvite.loading" class="text-lowercase"> Carregando token...</span>
                                        <a v-else-if="fastLinkConvite.token" class="text-lowercase" :href="fastLinkConvite.url" target="_blank"> {{fastLinkConvite.url}} </a>
                                      </p>
                                    </div>                                                                                             
                                  </div>
                                  <div
                                    v-else
                                    class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                  >  
                                    <p>Convite pendente: <span class="text-danger"> Não </span></p> 
                                  </div>
                                </div>
                                <div class="col-auto">
                                  <div
                                    v-if="fastUsuarioEditar.ativo == 'S'"
                                    class="h5 mb-0 font-weight-bold text-success"
                                  >
                                    <span
                                      v-if="fastUsuarioEditar.status == 'A'"
                                      class="btn btn-success"
                                    >Ativado</span>  
                                    <span
                                      v-else-if="fastUsuarioEditar.status == 'B'"
                                      class="btn btn-danger"
                                    >Conta bloqueada</span>  
                                    <span
                                      v-else-if="fastUsuarioEditar.status == 'I'"
                                      class="btn btn-danger"
                                    >Conta inativa</span>  
                                  </div>
                                  <div
                                    v-else
                                    class="h5 mb-0 font-weight-bold text-danger"
                                  >
                                    <span class="btn btn-danger">Desativado</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div v-if="fastUsuarioEditar.id_usuario_plataforma" class="row mt-4">
                          <div class="col-sm-12 col-md-12 col-lg-3 text-center">
                            <div
                              class="fast-cursor-pointer p-4 rounded border text-warning"
                              @click="showModal('modalUsuarioReenviar')"
                            >
                              <b-icon-arrow-counterclockwise font-scale="3" />
                              <h6 class="mt-2">
                                Reenviar convite
                              </h6>
                            </div>
                          </div>
                          <div
                            v-if="fastUsuarioEditar.ativo == 'S'"
                            class="col-sm-12 col-md-12 col-lg-3 text-center"
                          >
                            <div
                              class="fast-cursor-pointer p-4 rounded border text-danger"
                              @click="showModal('modalUsuarioDesativar')"
                            >
                              <b-icon-arrow-down-circle font-scale="3" />
                              <h6 class="mt-2">
                                Desativar
                              </h6>
                            </div>
                          </div>
                          <div
                            v-else
                            class="col-sm-12 col-md-12 col-lg-3 text-center"
                          >
                            <div
                              class="fast-cursor-pointer p-4 rounded border text-success"
                              @click="showModal('modalUsuarioAtivar')"
                            >
                              <b-icon-arrow-up-circle font-scale="3" />
                              <h6 class="mt-2">
                                Ativar
                              </h6>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-3 text-center">
                            <div
                              class="fast-cursor-pointer p-4 rounded border text-secondary"
                              style="cursor: not-allowed; background: rgb(154 154 154)"
                              @click.prevent="exibeToasty('Para excluir consulte um administrador da plataforma', 'error')"
                            >
                              <b-icon-trash font-scale="3" />
                              <h6 class="mt-2">
                                Excluir
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div v-else class="row mt-4">
                            <div class="col-sm-12 col-md-12 col-lg-3 text-center">
                            <div
                              class="fast-cursor-pointer p-4 rounded border text-warning"
                              @click="showModal('modalAtivarLogin')"
                            >
                              <b-icon-arrow-counterclockwise font-scale="3" />
                              <h6 class="mt-2">
                                Ativar login
                              </h6>
                            </div>
                          </div>
                        </div>
                      <div class="row mt-4">                                        
                        <div class="col-12 mt-2 mb-2">
                          <div class="form-group mb-0">
                            <!-- <button
                              v-if="fastEmailCadastro.btnCadastrar"
                              href="#"
                              type="button"
                              class="btn btn-primary mr-1"
                              @click="alteraPlataformaPessoa()"
                            >
                              <small>Salvar alterações</small>                                
                            </button> -->
                            <button
                              href="#"
                              type="button"
                              class="btn btn-secondary mr-1"
                              @click.prevent="cancelaConviteUsuarios()"
                            >
                              <small>Cancelar</small>                                
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <!-- /Conteúdo principal -->
        </div>
        <div class="container">
          <div class="mt-5 mb-5">
            <a
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->

    <!-- modalAtivarLogin -->
    <modal
      name="modalAtivarLogin"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Ativar login para usuário?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAtivarLogin')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <h6>Ativando o login o usuário irá receber um email com o link de ativação</h6>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaLoginAtivar()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalUsuarioDesativar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Desativar usuário?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioDesativar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaUsuarioDesativar()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalUsuarioAtivar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Ativar usuário?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioAtivar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaUsuarioAtivar()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalUsuarioReenviar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Reenviar convite?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioReenviar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="confirmaUsuarioReenviar()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import FastCadastroPessoa from "../components/FastCadastroPessoa";
import FastPessoaGatewaysPagamento from "../components/FastPessoaGatewaysPagamento.vue";

export default {
  name: "HomeInternoTesourariaPessoas",
  components: {
    Pagination,
    FastCadastroPessoa,
    FastPessoaGatewaysPagamento
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Navegação entre paginas
      fastNavegacao: {
        exibirPessoas: true,
        cadastrarPessoa: false,
        exibirDetalhes: false
      },
      // Lista de pessoas
      fastPessoasPlataforma: {
        total: [],
        filtro: [],
        paginacao: [],
        filtroParametros: {
          nome_email: {
            ativo: false,
            valor: 0
          },
          perfil: {
            ativo: false,
            valor: 0
          }
        },
        loading: true
      },
      // Editar usuário
      fastUsuarioEditar: {
        id_usuario: "",
        first_name: "",
        last_name: "",
        image: "",
        ativo: "",
        aceito: "",
        data_convite_envio: "",
        data_aceite: null,
        token: "",
        administrador: "",
        edita_cursos: "",
        edita_usuarios: "",
        edita_professores: "",
        edita_plataforma: "",
        edita_matriz: "",
        edita_financeiro: "",
        edita_alunos: "",
        edita_admins: "",
        professor: "",
        aluno: "",
        tesouraria: "",
        secretario: "",
        responsavel: ""
      },
      // Convite por email
      modelConviteUsuario: {
        fast_plataforma_usuario: {
          id_plataforma: this.$route.params.id_plataforma,
          administrador: "",
          edita_cursos: "",
          edita_usuarios: "",
          edita_professores: "",
          edita_plataforma: "",
          edita_matriz: "",
          edita_financeiro: "",
          edita_alunos: "",
          edita_admins: "",
          professor: "",
          aluno: "",
          tesouraria: "",
          secretario: "",
          responsavel: ""
        },
        fast_usuario: {
          email: ""
        },
        fast_plataforma_professor: {
          first_name: "",
          last_name: "",
          licenciatura: "",
          image: ""
        }
      },
      fastEmailsConvite: [],
      fastEmail: {
        id_usuario: "",
        id_pessoa: 0,
        email: "",
        status: "Aguardando...",
        classe: "text-info",
        code: "",
        first_name: "Nome",
        last_name: "não identificado",
        image: "",
        administrador: "",
        professor: "",
        aluno: "",
        tesouraria: "",
        secretario: "",
        responsavel: ""
      },
      fastBtnConvidar: true,
      fastListaEmailsMassica: "",
      // Cadastro individual
      modelCadastroUsuario: {
        fast_plataforma_usuario: {
          id_plataforma: this.$route.params.id_plataforma,
          administrador: "",
          edita_cursos: "",
          edita_usuarios: "",
          edita_professores: "",
          edita_plataforma: "",
          edita_matriz: "",
          edita_financeiro: "",
          edita_alunos: "",
          edita_admins: "",
          professor: "",
          aluno: "",
          tesouraria: "",
          secretario: "",
          responsavel: ""
        },
        fast_usuario: {
          id_usuario: 0,
          first_name: "",
          last_name: "",
          email: ""
        },
        fast_pessoa: {
          id_pessoa: 0,
          id_usuario: "",
          cpf_cnpj: "",
          nome_razao: "",
          sobrenome_fantasia: "",
          datanascimento_abertura: null,
          banco: "",
          codigo_banco: "",
          sexo: "",
          id_plataforma: 0,
          payee_code_gerencianet: ""
        },
        fast_pessoa_endereco: {
          tipo: "",
          uf: "",
          cep: "",
          logradouro: "",
          bairro: "",
          cidade: "",
          complemento: "",
          numero: ""
        },
        fast_pessoa_telefone: {
          tipo: 1,
          telefone: "",
          ramal: ""
        },
        fast_usuario_parametro: {
          image: ""
        },
        fast_plataforma_professor: {
          first_name: "",
          last_name: "",
          licenciatura: "",
          image: ""
        },
        envia_email: true,
        ids_funcao_pessoa: "",
        status: "",
        code: "",
        classe: "text-info",
      },
      fastEmailCadastro: {
        id_usuario: "",
        id_pessoa: 0,
        email: "",
        status: "",
        classe: "text-info",
        code: "",
        first_name: "Nome",
        last_name: "não identificado",
        image: "",
        administrador: "",
        professor: "",
        aluno: "",
        tesouraria: "",
        secretario: "",
        responsavel: "",
        btnCadastrar: true
      },
      readonly: false,
      fastFuncaoPessoa: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },
      // Link do convite para admin do fast
      fastLinkConvite: {
        url: "",
        token: "",
        loading: false
      }
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.getUsuariosPlataforma(this.$route.params.id_plataforma)
            this.getFuncaoPessoa(this.$route.params.id_plataforma)
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getFuncaoPessoa(id_plataforma){      
      this.fastFuncaoPessoa.loading = true
      this.promiseGetFastApi("api/fast_tesouraria_funcao_pessoa/lista", "id_plataforma=" + id_plataforma).then(res => {
        console.log("getFuncaoPessoa", res)
        if (res.length) {
          res.forEach(e => e.ativa = false)
          this.fastFuncaoPessoa.total = res
          this.fastFuncaoPessoa.filtro = res
        }
        else {
          this.fastFuncaoPessoa.total = []
          this.fastFuncaoPessoa.filtro = []
        }

        this.fastFuncaoPessoa.loading = false
      }).catch(e => {
        console.log(e)
        this.exibeToasty(e, "error")
        this.fastFuncaoPessoa.loading = false
      })
    },
    async getUsuariosPlataforma(id_plataforma){
      this.fastPessoasPlataforma.loading = true
      let param = `id_pessoa=0&id_plataforma=${id_plataforma}`

      this.promiseGetFastApi("api/fast_tesouraria_funcao_pessoa/lista_pessoas", param).then(obj => {
        console.log("getUsuariosPlataforma", obj)
        obj.forEach(e => {
          if (!e.nome_razao) e.nome_razao = ""
          if (!e.sobrenome_fantasia) e.sobrenome_fantasia = ""
        })
        if (obj.length) {
          this.fastPessoasPlataforma.total = obj
          this.fastPessoasPlataforma.filtro = obj
        }
        else {
          this.fastPessoasPlataforma.total = []
          this.fastPessoasPlataforma.filtro = []
        }          
        this.fastPessoasPlataforma.loading = false  
      }).catch(e => {      
        console.log(e);
        this.exibeToasty("Erro ao buscar alunos", "error");
        this.fastPessoasPlataforma.loading = false
      })
    },
    exibeModalEditarUsuario(e){
      this.limpaPropsObj(this.modelCadastroUsuario)
      this.fastFuncaoPessoa.total.forEach(ft => ft.ativa = false)

      this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", `id_pessoa=${e.id_pessoa}&id_plataforma=${e.id_plataforma}`)
        .then(res => {
          // Verifica JSON
          const [err, obj] = this.safeJsonParse(res);
          if (err) {
            this.exibeToasty("Falha ao interpretar json", "error") 
            console.log('Falha ao interpretar json: ' + err.message);
          } else {
            if (!obj.error) {
              console.log("busca_pessoa_secretaria", obj)
              if (obj.datanascimento_abertura)
                obj.datanascimento_abertura = obj.datanascimento_abertura.split(
                  "T"
                )[0];
              else obj.datanascimento_abertura = "";
              if (!obj.nome_razao) obj.nome_razao = "";
              if (!obj.sobrenome_fantasia) obj.sobrenome_fantasia = "";
              if (!obj.cpf_cnpj) obj.cpf_cnpj = "";
              if (!obj.cep) obj.cep = "";
              if (!obj.cidade) obj.cidade = "";
              if (!obj.complemento) obj.complemento = "";
              if (!obj.logradouro) obj.logradouro = "";
              if (!obj.numero) obj.numero = "";
              if (!obj.sexo) obj.sexo = "";
              if (obj.sexo) {
                if (!obj.sexo.trim()) obj.sexo = "";
              }
              if (!obj.telefone) obj.telefone = "";
              if (!obj.uf) obj.uf = "";
              if (!obj.bairro) obj.bairro = "";
              if (!obj.payee_code_gerencianet) obj.payee_code_gerencianet = "";

              if (!obj.id_usuario)
                this.exibeToasty(
                  "Não foi possível identificar o id_usuario. Consulte um administrador do sistema.",
                  "error"
                );
              if (!obj.id_pessoa)
                this.exibeToasty(
                  "Não foi possível identificar o id_pessoa. Consulte um administrador do sistema.",
                  "error"
                );
              if (!obj.email)
                this.exibeToasty(
                  "Não foi possível identificar o email do usuário. Consulte um administrador do sistema.",
                  "error"
                );

               // this.$store.state.fastPessoa = obj;
                // Não copia por referência
                //this.$store.state.fastPessoaReadOnly = Object.assign({}, obj);

                this.fastUsuarioEditar = e   
                
                this.modelCadastroUsuario.fast_usuario.id_usuario = obj.id_usuario
                this.modelCadastroUsuario.fast_usuario.email = obj.email

                this.modelCadastroUsuario.fast_pessoa.id_plataforma = e.id_plataforma
                this.modelCadastroUsuario.fast_pessoa.id_pessoa = obj.id_pessoa
                this.modelCadastroUsuario.fast_pessoa.nome_razao = obj.nome_razao
                this.modelCadastroUsuario.fast_pessoa.sobrenome_fantasia = obj.sobrenome_fantasia
                this.modelCadastroUsuario.fast_pessoa.datanascimento_abertura = obj.datanascimento_abertura
                this.modelCadastroUsuario.fast_pessoa.cpf_cnpj = obj.cpf_cnpj
                this.modelCadastroUsuario.fast_pessoa.sexo = obj.sexo 
                this.modelCadastroUsuario.fast_pessoa.banco = obj.banco
                this.modelCadastroUsuario.fast_pessoa.codigo_banco = obj.codigo_banco
                this.modelCadastroUsuario.fast_pessoa.payee_code_gerencianet = obj.payee_code_gerencianet

                

                this.modelCadastroUsuario.fast_pessoa_telefone.telefone = obj.telefone 

                this.modelCadastroUsuario.fast_pessoa_endereco.cep = obj.cep 
                this.modelCadastroUsuario.fast_pessoa_endereco.logradouro = obj.logradouro
                this.modelCadastroUsuario.fast_pessoa_endereco.numero = obj.numero
                this.modelCadastroUsuario.fast_pessoa_endereco.complemento = obj.complemento
                this.modelCadastroUsuario.fast_pessoa_endereco.bairro = obj.bairro
                this.modelCadastroUsuario.fast_pessoa_endereco.cidade = obj.cidade
                this.modelCadastroUsuario.fast_pessoa_endereco.uf = obj.uf

                this.promiseGetFastApi("api/fast_pessoa_funcao/lista", "id_plataforma=" + e.id_plataforma + "&id_pessoa=" + e.id_pessoa)
                .then(res => {
                  if (res.length) {
                    this.fastFuncaoPessoa.total.forEach(ft => {
                      res.forEach(fa => {
                        if (ft.id_funcao_pessoa == fa.id_funcao_pessoa) ft.ativa = true
                      })
                    })
                    console.log("this.fastFuncaoPessoa.total", this.fastFuncaoPessoa.total)
                  }
                }).catch(e => {
                  console.log(e)
                  this.exibeToasty(e, "error")
                })
                
                this.alteraFastNavegacao('exibirDetalhes')
              
          } else {
            this.exibeToasty("Erro ao recuperar pessoa", "error") 
          }
          this.$store.state.fastPessoa.loading = false
          }
        })
        .catch(e => {
          this.exibeToasty(e, "error")  
          this.$store.state.fastPessoa.loading = false  
        })

      
    },
    filtraPessoas(e) {
      console.log("this.fastPessoasPlataforma.filtroParametros", this.fastPessoasPlataforma.filtroParametros)

      if (this.fastPessoasPlataforma.filtroParametros.nome_email.valor.length > 2) this.fastPessoasPlataforma.filtroParametros.nome_email.ativo = true
      else
        this.fastPessoasPlataforma.filtroParametros.nome_email.ativo = false

      if (this.fastPessoasPlataforma.filtroParametros.perfil.valor) this.fastPessoasPlataforma.filtroParametros.perfil.ativo = true
      else
        this.fastPessoasPlataforma.filtroParametros.perfil.ativo = false

      

      if (!this.fastPessoasPlataforma.filtroParametros.nome_email.ativo && !this.fastPessoasPlataforma.filtroParametros.perfil.ativo) {
        this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.total
      } else {
        if (this.fastPessoasPlataforma.filtroParametros.nome_email.ativo) {
          this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.total.filter((e) => {
            return e.nome_razao.toLowerCase().indexOf(this.fastPessoasPlataforma.filtroParametros.nome_email.valor.toLowerCase()) != "-1" || e.sobrenome_fantasia.toLowerCase().indexOf(this.fastPessoasPlataforma.filtroParametros.nome_email.valor.toLowerCase()) != "-1" || e.email.toLowerCase().indexOf(this.fastPessoasPlataforma.filtroParametros.nome_email.valor.toLowerCase()) != "-1";
          });
        } else {
          this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.total
        }

        if (this.fastPessoasPlataforma.filtroParametros.perfil.ativo) {
          switch (this.fastPessoasPlataforma.filtroParametros.perfil.valor) {
            case "administrador":
              this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.filtro.filter((e) => {
                return e.administrador == "S"
              });
              break;
            case "aluno":
              this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.filtro.filter((e) => {
                return e.aluno == "S"
              });
              break;
            case "professor":
              this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.filtro.filter((e) => {
                return e.professor == "S"
              });
              break;
            case "secretaria":
              this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.filtro.filter((e) => {
                return e.secretario == "S"
              });
              break;
            case "tesouraria":
              this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.filtro.filter((e) => {
                return e.tesouraria == "S"
              });
              break;
            case "responsavel":
              this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.filtro.filter((e) => {
                return e.responsavel == "S"
              });
              break;
          
            default:
              break;
          }
        }
      }

    },
    // cadastrar
    exibeModalCadastrarPessoa(){
      this.limpaPropsObj(this.modelCadastroUsuario)
      console.log('exibeModalCadastrarPessoa')
      this.alteraFastNavegacao('cadastrarPessoa')
    },    
    async validaCadastrarPessoaTesouraria(){
      this.modelCadastroUsuario.status = ""
      this.modelCadastroUsuario.classe = "text-info"

      this.modelCadastroUsuario.fast_usuario.first_name = this.modelCadastroUsuario.fast_pessoa.nome_razao
      this.modelCadastroUsuario.fast_usuario.last_name = this.modelCadastroUsuario.fast_pessoa.sobrenome_fantasia

      let erros = []
      if (!this.modelCadastroUsuario.fast_usuario.first_name) erros.push("O nome é obrigatório")
      if (!this.modelCadastroUsuario.fast_usuario.last_name) erros.push("O sobrenome é obrigatório")
      if (!this.modelCadastroUsuario.fast_usuario.email) erros.push("O email é obrigatório")
      if (!this.modelCadastroUsuario.fast_pessoa.cpf_cnpj) erros.push(`O CPF/CNPJ é obrigatório`)            
      if (this.modelCadastroUsuario.fast_pessoa.cpf_cnpj) {
        if (!this.validaCpfCnpj(this.modelCadastroUsuario.fast_pessoa.cpf_cnpj)) erros.push("CPF/CNPJ inválido")
      }    

      if (!this.modelCadastroUsuario.fast_pessoa_endereco.cep) erros.push(`O CEP é obrigatório`)      
      if (this.modelCadastroUsuario.fast_pessoa_endereco.cep) {
        if (!/^[0-9]{8}$/.test(this.modelCadastroUsuario.fast_pessoa_endereco.cep.replace("-", ""))) erros.push("CEP inválido")
      }  
      if (!this.modelCadastroUsuario.fast_pessoa_endereco.logradouro) erros.push(`O endereço é obrigatório`)
      if (!this.modelCadastroUsuario.fast_pessoa_endereco.numero) erros.push(`O número é obrigatório`)
      if (!this.modelCadastroUsuario.fast_pessoa_endereco.bairro) erros.push(`O bairro é obrigatório`)
      if (!this.modelCadastroUsuario.fast_pessoa_endereco.cidade) erros.push(`A cidade é obrigatório`)
      if (!this.modelCadastroUsuario.fast_pessoa_endereco.uf) erros.push(`O estado é obrigatório`)
      
      if (!this.modelCadastroUsuario.fast_pessoa.datanascimento_abertura) erros.push(`O responsável não possui a data de nascimento cadastrada`)
     
      if (!this.modelCadastroUsuario.fast_usuario.email) erros.push(`O responsável não possui email cadastrado`)

      if (!this.modelCadastroUsuario.fast_pessoa_telefone.telefone) erros.push(`O responsável não possui telefone cadastrado`)
      if (!this.validaTelefoneFormatado(this.modelCadastroUsuario.fast_pessoa_telefone.telefone)) erros.push("O telefone está no formato incorreto")
      this.modelCadastroUsuario.fast_pessoa_telefone.tipo = 1

      if (this.modelCadastroUsuario.fast_pessoa_endereco.numero) this.modelCadastroUsuario.fast_pessoa_endereco.numero = this.modelCadastroUsuario.fast_pessoa_endereco.numero.trim()  
      this.modelCadastroUsuario.fast_pessoa_endereco.tipo = 1    

      if (erros.length) {
        this.modelCadastroUsuario.status = erros.join(", ")
        this.modelCadastroUsuario.classe = "text-danger"

        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        console.log("modelCadastroUsuario", this.modelCadastroUsuario)
        this.promiseGetFastApi("api/fast_plataforma_usuario/usuario_check", `email=${this.modelCadastroUsuario.fast_usuario.email}&id_plataforma=${this.$route.params.id_plataforma}`).then(res => {
          console.log("validaCadastrarPessoaTesouraria", res)
            const retorno = JSON.parse(res)
            if (retorno.error){
              this.modelCadastroUsuario.status = retorno.description
              this.modelCadastroUsuario.classe = "text-danger"
            } else {
              this.modelCadastroUsuario.status = retorno.description
              this.modelCadastroUsuario.classe = "text-success"
              this.modelCadastroUsuario.code = retorno.code
            }      

            switch (retorno.code) {
              case "email_on_plataform":
                this.modelCadastroUsuario.status = "Não é possível cadastrar pois o usuário já está associado à plataforma"              
                this.modelCadastroUsuario.classe = "text-danger"
                this.exibeToasty(this.modelCadastroUsuario.status, "error")
                break;
              case "email_on_system":
                this.modelCadastroUsuario.status = "Email já cadastrado no sistema"
                this.modelCadastroUsuario.classe = "text-warning"
                this.cadastrarPessoaTesouraria()
                break;
              case "email_unknown":
                this.modelCadastroUsuario.status = "Cadastrando pessoa. Aguarde..."
                this.modelCadastroUsuario.classe = "text-success"
                this.cadastrarPessoaTesouraria()
                break;          
              default:
                this.modelCadastroUsuario.status = "Código de retorno não identificado"
                this.modelCadastroUsuario.classe = "text-danger"
                this.exibeToasty(this.modelCadastroUsuario.status, "error")
                break;
            }   

          }).catch(e => { 
            console.log(e);
          this.modelCadastroUsuario.status = "Erro ao verificar email"
          this.modelCadastroUsuario.classe = "text-danger"
          this.exibeToasty(this.modelCadastroUsuario.status, "error")
        })
      }
    },
    cadastrarPessoaTesouraria(){
      this.modelCadastroUsuario.fast_plataforma_usuario.id_plataforma = this.$route.params.id_plataforma
      
      this.promisePostFastApi(this.modelCadastroUsuario, "api/fast_tesouraria_funcao_pessoa/cadastra_pessoa")
      .then(res => {
        console.log("cadastrarPessoaTesouraria", res)
        if (res.length) {
          this.fastPessoasPlataforma.total.push(res[0])
          this.cancelaConviteUsuarios()
        } else {
          this.exibeToasty("Erro ao cadastrar pessoa", "error")
        }
      })
      .catch(e => this.exibeToasty(e, "error"))
    },
    async alteraPlataformaPessoa() {
        this.getFastHoraServidor()
        // Validação
        .then(() => {
          let erros = []
          if (this.modelCadastroUsuario.fast_pessoa_telefone.telefone) {
            if (!this.validaTelefone(this.modelCadastroUsuario.fast_pessoa_telefone.telefone)) erros.push("O telefone está no formato incorreto")
          } 
          if (this.modelCadastroUsuario.fast_pessoa.datanascimento_abertura) {
            let objDataNasc = new Date(`${this.modelCadastroUsuario.fast_pessoa.datanascimento_abertura}T00:00:00`)
            let cincoAnosAtras = new Date(this.$store.state.fastDataAtualServidor.setFullYear(this.$store.state.fastDataAtualServidor.getFullYear() - 5))
            if (objDataNasc > cincoAnosAtras) erros.push("A data de nascimento deve ser superior a 5 anos atrás")
          }
          if (this.modelCadastroUsuario.fast_pessoa.cpf_cnpj) {
            if (!this.validaCpfCnpj(this.modelCadastroUsuario.fast_pessoa.cpf_cnpj)) erros.push("CPF/CNPJ inválido")
          }      
          if (this.modelCadastroUsuario.fast_pessoa.cep) {
            if (!/^[0-9]{8}$/.test(this.modelCadastroUsuario.fast_pessoa.cep.replace("-", ""))) erros.push("CEP inválido")
          }     
          return erros   
        })
        // Atualização
        .then(erros => {        
          if (erros.length) {
            erros.forEach(e =>  this.exibeToasty(e, "error"))
          } else {  
            const modelCadastroUsuario = {
              fast_pessoa: {
                id_usuario: this.modelCadastroUsuario.fast_usuario.id_usuario,
                id_pessoa: this.modelCadastroUsuario.fast_pessoa.id_pessoa,
                nome_razao: this.modelCadastroUsuario.fast_pessoa.nome_razao,
                sobrenome_fantasia: this.modelCadastroUsuario.fast_pessoa.sobrenome_fantasia,
                cpf_cnpj: this.modelCadastroUsuario.fast_pessoa.cpf_cnpj,
                datanascimento_abertura: this.modelCadastroUsuario.fast_pessoa.datanascimento_abertura,
                sexo: this.modelCadastroUsuario.fast_pessoa.sexo,
                id_plataforma: this.$route.params.id_plataforma,
                payee_code_gerencianet: this.modelCadastroUsuario.fast_pessoa.payee_code_gerencianet
              },
              fast_usuario: {
                id_usuario: this.modelCadastroUsuario.fast_usuario.id_usuario
              },
              fast_pessoa_endereco: {
                uf: this.modelCadastroUsuario.fast_pessoa_endereco.uf,
                cep: this.modelCadastroUsuario.fast_pessoa_endereco.cep,
                logradouro: this.modelCadastroUsuario.fast_pessoa_endereco.logradouro,
                bairro: this.modelCadastroUsuario.fast_pessoa_endereco.bairro,
                cidade: this.modelCadastroUsuario.fast_pessoa_endereco.cidade,
                complemento: this.modelCadastroUsuario.fast_pessoa_endereco.complemento,
                numero: this.modelCadastroUsuario.fast_pessoa_endereco.numero,
              },
              fast_pessoa_telefone: {
                telefone: this.modelCadastroUsuario.fast_pessoa_telefone.telefone,
              },
              id_plataforma: this.$route.params.id_plataforma
            };

            this.$store.state.fastCarregando = true;
            this.promisePostFastApi(modelCadastroUsuario, "api/fast_plataforma_usuario/atualiza_pessoa_secretaria")
            .then(res => {                
              if (res.length){
                
                let obj = JSON.parse(res);
                if (!obj.error) {
                  if (obj.datanascimento_abertura)
                    obj.datanascimento_abertura = obj.datanascimento_abertura.split(
                      "T"
                    )[0];
                  else obj.datanascimento_abertura = "";
                  if (!obj.nome_razao) obj.nome_razao = "";
                  if (!obj.sobrenome_fantasia) obj.sobrenome_fantasia = "";
                  if (!obj.cpf_cnpj) obj.cpf_cnpj = "";
                  if (!obj.cep) obj.cep = "";
                  if (!obj.cidade) obj.cidade = "";
                  if (!obj.complemento) obj.complemento = "";
                  if (!obj.logradouro) obj.logradouro = "";
                  if (!obj.numero) obj.numero = "";
                  if (!obj.sexo) obj.sexo = "";
                  if (obj.sexo) {
                    if (!obj.sexo.trim()) obj.sexo = "";
                  }
                  if (!obj.telefone) obj.telefone = "";
                  if (!obj.uf) obj.uf = "";
                  if (!obj.bairro) obj.bairro = "";

                  if (!obj.id_usuario)
                    this.exibeToasty(
                      "Não foi possível identificar o id_usuario. Consulte um administrador do sistema.",
                      "error"
                    );
                  if (!obj.id_pessoa)
                    this.exibeToasty(
                      "Não foi possível identificar o id_pessoa. Consulte um administrador do sistema.",
                      "error"
                    );
                  if (!obj.email)
                    this.exibeToasty(
                      "Não foi possível identificar o email do usuário. Consulte um administrador do sistema.",
                      "error"
                    );
                    
                    this.modelCadastroUsuario.fast_usuario.email = obj.email

                    this.modelCadastroUsuario.fast_pessoa.id_plataforma = obj.id_plataforma
                    this.modelCadastroUsuario.fast_pessoa.id_pessoa = obj.id_pessoa
                    this.modelCadastroUsuario.fast_pessoa.nome_razao = obj.nome_razao
                    this.modelCadastroUsuario.fast_pessoa.sobrenome_fantasia = obj.sobrenome_fantasia
                    this.modelCadastroUsuario.fast_pessoa.datanascimento_abertura = obj.datanascimento_abertura
                    this.modelCadastroUsuario.fast_pessoa.cpf_cnpj = obj.cpf_cnpj
                    this.modelCadastroUsuario.fast_pessoa.sexo = obj.sexo 
                    this.modelCadastroUsuario.fast_pessoa.banco = obj.banco
                    this.modelCadastroUsuario.fast_pessoa.codigo_banco = obj.codigo_banco

                    this.modelCadastroUsuario.fast_pessoa_telefone.telefone = obj.telefone 

                    this.modelCadastroUsuario.fast_pessoa_endereco.cep = obj.cep 
                    this.modelCadastroUsuario.fast_pessoa_endereco.logradouro = obj.logradouro
                    this.modelCadastroUsuario.fast_pessoa_endereco.numero = obj.numero
                    this.modelCadastroUsuario.fast_pessoa_endereco.complemento = obj.complemento
                    this.modelCadastroUsuario.fast_pessoa_endereco.bairro = obj.bairro
                    this.modelCadastroUsuario.fast_pessoa_endereco.cidade = obj.cidade
                    this.modelCadastroUsuario.fast_pessoa_endereco.uf = obj.uf

                  this.exibeToasty("Perfil alterado com sucesso", "success")   

                } else {
                  this.exibeToasty(`Erro ao salvar alterações`, "error")
                }                          
              } else {
                this.exibeToasty(`Erro ao salvar alterações`, "error")
              }
              this.$store.state.fastCarregando = false
            })
            .catch((e) => {                
              this.exibeToasty(`${e}`, "error")
              this.$store.state.fastCarregando = false
            });             
          }  
        })
        .catch(e => {
          this.$store.state.fastCarregando = false
          this.exibeToasty(e, "error")
          console.log(e)
        })      
    },
    // Busca cep api correio
    async buscaCEPAluno(){
      if (this.modelCadastroUsuario.fast_pessoa_endereco.cep.length == 9) {
        this.buscaCEPCorreio(this.modelCadastroUsuario.fast_pessoa_endereco.cep).then(e => {
          if (e.cep){
            this.modelCadastroUsuario.fast_pessoa_endereco.cep = e.cep;
            this.modelCadastroUsuario.fast_pessoa_endereco.logradouro = e.logradouro;
            this.modelCadastroUsuario.fast_pessoa_endereco.bairro = e.bairro;
            this.modelCadastroUsuario.fast_pessoa_endereco.cidade = e.cidade;
            this.modelCadastroUsuario.fast_pessoa_endereco.uf = e.uf;
          } else {
            this.exibeToasty("CEP não encontrado", "error")
          }
        }).catch(e => this.exibeToasty(e, "error"))
      }      
    },
    // Convite através de email
    adicionaEmailLista(){
      let email = this.fastEmail.email.trim()
      if (this.validateEmail(email)) {
        let emailInserido = this.fastEmailsConvite.filter(e => e.email == email)
        if (!emailInserido.length) {
          this.fastEmailsConvite.push({ id_usuario: "", id_pessoa: 0, email: email, status: "Adicionado", classe: "text-success", code: "", first_name: "Nome", last_name: "não identificado", image: "" })
          this.fastEmail.id_usuario = ""
          this.fastEmail.id_pessoa = ""
          this.fastEmail.email = ""
          this.fastEmail.status = "Aguardando..."
          this.fastEmail.classe = "text-info"
          this.fastEmail.code = ""
          this.fastEmail.first_name = "Nome"
          this.fastEmail.last_name = "não identificado"
          this.fastEmail.image = ""
          this.fastEmail.administrador = ""
          this.fastEmail.professor = ""
          this.fastEmail.aluno = ""
          this.fastEmail.tesouraria = ""
          this.fastEmail.secretario = ""
          this.fastEmail.responsavel = ""
        } else {
          this.exibeToasty("Email já consta na lista", "error");
        }        
        
      } else {
        this.exibeToasty("Insira um email válido", "error");
      }      
    },
    removeEmailLista(email){
      this.fastEmailsConvite = this.fastEmailsConvite.filter(function(e){ 
          return e.email != email; 
      });
    },
    convidarUsuarios(){
      this.fastBtnConvidar = false
      this.fastEmailsConvite.forEach(e => {
        e.status = "Verificando email na base..."
        this.promiseGetFastApi("api/fast_plataforma_usuario/usuario_check", `email=${e.email}&id_plataforma=${this.$route.params.id_plataforma}`).then(res => {
          const retorno = JSON.parse(res)
          if (retorno.error){
            e.status = retorno.description
            e.classe = "text-danger"
          } else {
            e.status = retorno.description
            e.classe = "text-success"
            e.code = retorno.code
          }      

          switch (retorno.code) {
            case "email_on_plataform":
              e.status = "Não é possível convidar pois o usuário já está associado à plataforma"              
              e.classe = "text-danger"
              e.first_name = retorno.first_name
              e.last_name = retorno.last_name
              e.image = retorno.image
              e.id_usuario = retorno.id_usuario
              e.id_pessoa = retorno.id_pessoa
              break;
            case "email_on_system":
              e.status = "Email já cadastrado no sistema. Confirme o convite"
              e.classe = "text-warning"
              e.first_name = retorno.first_name
              e.last_name = retorno.last_name
              e.image = retorno.image
              e.id_usuario = retorno.id_usuario
              e.id_pessoa = retorno.id_pessoa
              break;
            case "email_unknown":
              e.status = "Cadastrando novo usuário. Aguarde..."
              e.classe = "text-success"
              this.cadastrarUsuario('alunos', e)
              break;          
            default:
              e.status = "Código de retorno não identificado"
              e.classe = "text-danger"
              break;
          }   

        }).catch(e => {         
          e.status = "Erro ao verificar email"
          e.classe = "text-danger"
        })
      })
    },
    cancelaConviteUsuarios(){
      this.fastBtnConvidar = true; 
      this.fastEmailsConvite = []
      this.fastEmailCadastro = {
        id_usuario: "",
        id_pessoa: 0,
        email: "",
        status: "",
        classe: "text-info",
        code: "",
        first_name: "Nome",
        last_name: "não identificado",
        image: "",
        btnCadastrar: true
      };
      this.modelCadastroUsuario = {
        fast_plataforma_usuario: {
          id_plataforma: this.$route.params.id_plataforma,
          administrador: "",
          edita_cursos: "",
          edita_usuarios: "",
          edita_professores: "",
          edita_plataforma: "",
          edita_matriz: "",
          edita_financeiro: "",
          edita_alunos: "",
          edita_admins: "",
          professor: "",
          aluno: "",
          tesouraria: "",
          secretario: "",
          responsavel: ""
        },
        fast_usuario: {
          first_name: "",
          last_name: "",
          email: ""
        },
        fast_pessoa: {
          cpf_cnpj: "",
          nome_razao: "",
          sobrenome_fantasia: "",
          datanascimento_abertura: null,
          banco: "",
          codigo_banco: "",
          sexo: "",
        },
        fast_pessoa_endereco: {
          tipo: "",
          uf: "",
          cep: "",
          logradouro: "",
          bairro: "",
          cidade: "",
          complemento: "",
          numero: ""
        },
        fast_pessoa_telefone: {
          tipo: 1,
          telefone: "",
          ramal: ""
        },
        fast_usuario_parametro: {
          image: ""
        },
        fast_plataforma_professor: {
          first_name: "",
          last_name: "",
          licenciatura: "",
          image: ""
        },
        envia_email: true,
        ids_funcao_pessoa: "",
        status: "",
        code: "",
        classe: "text-info",
      };
      this.alteraFastNavegacao('exibirPessoas');       
    },
    separaListaEmailsMassiva() {
      if (this.fastListaEmailsMassica.length) {
        let listaEmails = this.fastListaEmailsMassica.split("\n");
        listaEmails.forEach((e) => e = e.trim());
        if (listaEmails.length) {
          listaEmails.forEach(email => {
            console.log(this.validateEmail(email))
            let emailInserido = this.fastEmailsConvite.filter(e => e.email == email)            
            if (!emailInserido.length) {
              if (this.validateEmail(email)) this.fastEmailsConvite.push({ email: email, status: "Adicionado", classe: "text-success", code: "", first_name: "Nome", last_name: "não identificado", image: "" })
              else
                this.exibeToasty(`O email ${email} é inválido`, "error");
            } else {
              this.exibeToasty(`O email ${email} já foi inserido`, "error");
            }          
          })
        }
        this.hideModal('modalListaEmails')
        this.fastListaEmailsMassica = ""
      } else {
        this.exibeToasty("Insira ao menos um email válido", "error");
      }
    },
    cadastrarUsuario(perfil, fastEmail){
      fastEmail.status = "Cadastrando usuário. Aguarde..."
      switch (perfil) {
        case 'administradores':          
          
          break;
        case 'professores':
          
          break;
        case 'alunos':
          this.modelConviteUsuario = {
            fast_plataforma_usuario: {
              id_plataforma: this.$route.params.id_plataforma,
              administrador: "N",
              edita_cursos: "N",
              edita_usuarios: "N",
              edita_professores: "N",
              edita_plataforma: "N",
              edita_matriz: "N",
              edita_financeiro: "N",
              edita_alunos: "N",
              edita_admins: "N",
              professor: "N",
              aluno: "S",
              tesouraria: "N",
              secretario: "N",
              responsavel: "N"
            },
            fast_usuario: {
              email: fastEmail.email
            },
            fast_plataforma_professor: {
              first_name: "",
              last_name: "",
              licenciatura: "",
              image: ""
            }
          }

          this.promisePostFastApi(this.modelConviteUsuario, "api/fast_plataforma_usuario/convida_usuario").then(res => {
            console.log("cadastrarUsuario", res)
            const retorno = JSON.parse(res)
            if (retorno.error) {
              fastEmail.status = retorno.description
              fastEmail.classe = "text-danger"
            } else {
               if (retorno.code == "OK") {
                 fastEmail.status = "Convite enviado com sucesso"
                 fastEmail.code = "OK"
                 fastEmail.classe = "text-success"                 
               } else {
                 fastEmail.status = "Erro desconhecido. Contate o administrador do sistema."
                fastEmail.classe = "text-danger"
               }
            }
          }).catch(e => {       
            console.log(e);
            fastEmail.status = "Erro ao convidar aluno"
            fastEmail.classe = "text-danger"
          })
          
          break;
        case 'tesouraria':
          
          break;
        case 'secretario':
          
          break;
        case 'responsavel':
          
          break;
        default:
          break;
      }
    },
    // Cadastro individual
    verificaCadastrarUsuarioCompleto(perfil){
      let erros = []
      if (!this.modelCadastroUsuario.fast_usuario.first_name) erros.push("O nome é obrigatório")
      if (!this.modelCadastroUsuario.fast_usuario.last_name) erros.push("O sobrenome é obrigatório")
      if (!this.modelCadastroUsuario.fast_usuario.email) erros.push("O email é obrigatório")

      switch (perfil) {
        case 'administradores':          
          
          break;
        case 'professores':
          
          break;
        case 'alunos':
          this.modelCadastroUsuario.fast_plataforma_usuario.administrador = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_cursos = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_usuarios = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_professores = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_plataforma = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_matriz = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_financeiro = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_alunos = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_admins = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.professor = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.aluno = "S"
          this.modelCadastroUsuario.fast_plataforma_usuario.tesouraria = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.secretario = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.responsavel = "N"
          break;
        case 'tesouraria':
          
          break;
        case 'secretario':
          
          break;
        case 'responsavel':
          
          break;
        default:
          break;
      }

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.fastEmailCadastro.btnCadastrar = false

        this.fastEmailCadastro.status = "Verificando email na base..."
        this.promiseGetFastApi("api/fast_plataforma_usuario/usuario_check", `email=${this.modelCadastroUsuario.fast_usuario.email}&id_plataforma=${this.$route.params.id_plataforma}`).then(res => {
          const retorno = JSON.parse(res)
          if (retorno.error){
            this.exibeToasty(retorno.description, "error")
          } else {
            this.fastEmailCadastro.code = retorno.code

            switch (retorno.code) {
            case "email_on_plataform":
              this.fastEmailCadastro.status = "Não é possível convidar pois o usuário já está associado à plataforma"              
              this.fastEmailCadastro.classe = "text-danger"
              this.fastEmailCadastro.first_name = retorno.first_name
              this.fastEmailCadastro.last_name = retorno.last_name
              this.fastEmailCadastro.image = retorno.image
              this.fastEmailCadastro.id_usuario = retorno.id_usuario
              this.fastEmailCadastro.id_pessoa = retorno.id_pessoa              
              this.fastEmailCadastro.email = retorno.email
              this.fastEmailCadastro.administrador = retorno.administrador
              this.fastEmailCadastro.professor = retorno.professor
              this.fastEmailCadastro.aluno = retorno.aluno
              this.fastEmailCadastro.tesouraria = retorno.tesouraria
              this.fastEmailCadastro.secretario = retorno.secretario
              this.fastEmailCadastro.responsavel = retorno.responsavel
              break;
            case "email_on_system":
              this.fastEmailCadastro.status = "Email já cadastrado no sistema. Confirme o convite"
              this.fastEmailCadastro.classe = "text-warning"
              this.fastEmailCadastro.first_name = retorno.first_name
              this.fastEmailCadastro.last_name = retorno.last_name
              this.fastEmailCadastro.image = retorno.image
              this.fastEmailCadastro.id_usuario = retorno.id_usuario
              this.fastEmailCadastro.email = retorno.email
              break;
            case "email_unknown":
              this.fastEmailCadastro.status = "Cadastrando novo usuário. Aguarde..."
              this.fastEmailCadastro.classe = "text-success"
              this.cadastrarUsuarioCompleto()
              break;          
            default:
              e.status = "Código de retorno não identificado"
              e.classe = "text-danger"
              break;
          }   
          }  

        }).catch(e => {         
          e.status = "Erro ao verificar email"
          e.classe = "text-danger"
        })

        
      }
    },
    cadastrarUsuarioCompleto(){
      this.promisePostFastApi(this.modelCadastroUsuario, "api/fast_plataforma_usuario/cadastro_usuario").then(res => {
        console.log("cadastrarUsuarioCompleto", res)
        const retorno = JSON.parse(res)
        if (retorno.error) {
          this.exibeToasty(e.description, "error");
          this.fastEmailCadastro.btnCadastrar = true
        } else {
          if (retorno.code == "OK") {
            this.getUsuariosPlataforma(this.$route.params.id_plataforma, 'alunos') 
            this.exibeToasty("Usuário cadastrado com sucesso", "success");
            this.cancelaConviteUsuarios('alunos')
            this.fastEmailCadastro = {
              id_usuario: 0,
              id_pessoa: 0,
              email: "",
              status: "",
              classe: "text-info",
              code: "",
              first_name: "Nome",
              last_name: "não identificado",
              image: "",
              btnCadastrar: true
            }
            this.modelCadastroUsuario = {
              fast_plataforma_usuario: {
                id_plataforma: this.$route.params.id_plataforma,
                administrador: "",
                edita_cursos: "",
                edita_usuarios: "",
                edita_professores: "",
                edita_plataforma: "",
                edita_matriz: "",
                edita_financeiro: "",
                edita_alunos: "",
                edita_admins: "",
                professor: "",
                aluno: "",
                tesouraria: "",
                secretario: "",
                responsavel: ""
              },
              fast_usuario: {
                first_name: "",
                last_name: "",
                email: ""
              },
              fast_pessoa: {
                cpf_cnpj: "",
                datanascimento_abertura: null,
                banco: "",
                codigo_banco: "",
                sexo: "",
                payee_code_gerencianet: ""
              },
              fast_pessoa_endereco: {
                tipo: "",
                uf: "",
                cep: "",
                logradouro: "",
                bairro: "",
                cidade: "",
                complemento: "",
                numero: ""
              },
              fast_pessoa_telefone: {
                tipo: "",
                telefone: "",
                ramal: ""
              },
              fast_usuario_parametro: {
                image: ""
              },
              fast_plataforma_professor: {
                first_name: "",
                last_name: "",
                licenciatura: "",
                image: ""
              },
              envia_email: true,
              ids_funcao_pessoa: "",
              status: "",
              classe: "text-info",
            }
          }
        }
      }).catch(e => {         
        console.log(e);        
        this.exibeToasty("Erro ao cadastrar usuário", "error");
        this.fastEmailCadastro.btnCadastrar = true
      })
    },
    // Exclusão
    async confirmaUsuarioExcluir() {
      this.$store.state.fastCarregando = true
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/exclui", this.fastAjaxOptions("POST", JSON.stringify(this.fastUsuarioEditar)));
        let json = await resp.json();
        if (!json.length) {

          let fastAlunosTotal = this.fastAlunosTotal.filter((u) => { 
              return u.id_usuario != this.fastUsuarioEditar.id_usuario; 
          });

          this.fastAlunosTotal = fastAlunosTotal
          this.fastAlunosFiltro = fastAlunosTotal

          this.fastUsuarioEditar = {
            id_usuario: "",
            first_name: "",
            last_name: "",
            image: "",
            ativo: "",
            aceito: "",
            data_convite_envio: "",
            data_aceite: null,
            token: "",
            administrador: "",
            edita_cursos: "",
            edita_usuarios: "",
            edita_professores: "",
            edita_plataforma: "",
            edita_matriz: "",
            edita_financeiro: "",
            edita_alunos: "",
            edita_admins: "",
            professor: "",
            aluno: "",
            tesouraria: "",
            secretario: "",
            responsavel: ""
          }
          this.hideModal("modalUsuarioExcluir");
          this.exibeToasty("Usuário excluído com sucesso", "success")
          this.alteraFastNavegacao('exibirPessoas')
          this.$store.state.fastCarregando = false
        } else {
          this.exibeToasty("Erro ao excluir", "error")
          this.$store.state.fastCarregando = false
        }
        
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Desativação
    async confirmaUsuarioDesativar() {
      this.$store.state.fastCarregando = true
      const fast_plataforma_usuario = {
        id_usuario: this.fastUsuarioEditar.id_usuario,
        id_plataforma: this.$route.params.id_plataforma,
        ativo: "N",
        convite_enviado: "N",
        token: "desativado",
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/atualiza_usuario", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();
        this.fastUsuarioEditar.ativo = "N"
        this.fastUsuarioEditar.token = "desativado"
        this.hideModal("modalUsuarioDesativar");
        this.exibeToasty("Usuário desativado com sucesso", "success")
        this.$store.state.fastCarregando = false
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false
      }
    },
    // Ativação
     async confirmaUsuarioAtivar() {
      this.$store.state.fastCarregando = true
      const fast_plataforma_usuario = {
        id_usuario: this.fastUsuarioEditar.id_usuario,
        id_plataforma: this.$route.params.id_plataforma,
        ativo: "S",
        convite_enviado: "S",
        token: "ativado"
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/atualiza_usuario", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();
        let obj = Array.from(json);

        this.fastUsuarioEditar.ativo = "S"
        this.fastUsuarioEditar.token = "ativado"

        this.hideModal("modalUsuarioAtivar");
        this.exibeToasty("Usuário ativado com sucesso", "success")
        this.$store.state.fastCarregando = false
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false
      }
    },
    // Reenviar convite
    async confirmaUsuarioReenviar(){
      this.$store.state.fastCarregando = true
      const fast_plataforma_usuario = {        
          id_usuario: this.fastUsuarioEditar.id_usuario,
          id_plataforma: this.$route.params.id_plataforma,
          convite_enviado: "S",
          aceito: "N",
          data_aceite: null,
          ativo: "N",
          token: '1',
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/atualiza_usuario", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();
        if (json.length) {
          this.fastUsuarioEditar.convite_enviado = "S"
          this.fastUsuarioEditar.aceito = "N"
          this.fastUsuarioEditar.data_aceite = ""
          this.fastUsuarioEditar.ativo = "N"
          this.fastUsuarioEditar.token = json[0].token;

          this.hideModal("modalUsuarioReenviar");
          this.exibeToasty("Convite reenviado com sucesso", "success")
          this.$store.state.fastCarregando = false
        } else {
          this.exibeToasty("Erro ao enviar convite", "error")
          this.$store.state.fastCarregando = false
        }
        
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false
      }
    },
    alteraFuncaoNovaPessoa(e){
      console.log("alteraFuncaoNovaPessoa", e)
      let id_funcao_pessoa = e.target.value
      let checked = e.target.checked
      let incluido = false
      let arrayIds = this.modelCadastroUsuario.ids_funcao_pessoa.split(",")

      arrayIds.forEach(i => {
        if (i == id_funcao_pessoa) incluido = true
      })
      
      if (checked) {
        if (!incluido) arrayIds.push(id_funcao_pessoa)
      } else {
        arrayIds = arrayIds.filter(e => id_funcao_pessoa != e)
      }      

      this.modelCadastroUsuario.ids_funcao_pessoa = arrayIds.join(",")

      console.log("this.modelCadastroUsuario.ids_funcao_pessoa", this.modelCadastroUsuario.ids_funcao_pessoa)
    },
    alteraFuncaoEditarPessoa(e) {
      let obj = {
        id_pessoa: this.modelCadastroUsuario.fast_pessoa.id_pessoa,
        id_funcao_pessoa: e.target.value,
        id_plataforma: this.modelCadastroUsuario.fast_pessoa.id_plataforma
      }
      let acao = e.target.checked ? "insere" : "exclui"
      this.promisePostFastApi(obj, "api/fast_pessoa_funcao/" + acao)
        .then(res => {
          console.log("api/fast_pessoa_funcao/" + acao, res)
          if (res.length) {
            this.exibeToasty("Função alterada com sucesso", "success")

            this.fastFuncaoPessoa.total.forEach(ft => {
              ft.ativa = false
              res.forEach(fa => {
                if (ft.id_funcao_pessoa == fa.id_funcao_pessoa) ft.ativa = true
              })
            })

          } else {
            this.exibeToasty("Erro ao editar função", "error")
          }          
        }).catch((e) => {
          this.exibeToasty(e, "error")
        })
    },
    async confirmaLoginAtivar() {
      this.$store.state.fastCarregando = true
      const obj = {
        id_usuario: this.fastUsuarioEditar.id_usuario,
        id_plataforma: this.$route.params.id_plataforma
      };
      this.promisePostFastApi(obj, "api/fast_plataforma_usuario/ativa_login")
      .then(res => {
        console.log("ativa_login", res)
        if (res.length){
          this.exibeToasty("Perfil ativado com sucesso", "success") 
          this.exibeModalEditarUsuario(this.fastUsuarioEditar)
        } else {
          this.exibeToasty(json.error, "error")
        }
        this.$store.state.fastCarregando = false       
      }).catch((e) => {
        this.exibeToasty(e, "error")
        this.$store.state.fastCarregando = false
      })
    },
  },
};
</script>

<style></style>
